<template>
  <page class="bg-white divide-y divide-y-2 divide-neutral-50">
    <div
      v-if="isPageLoading"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>

    <div class="onboarding-page base-scrollbar relative bg-white">
      <div>
        <div class="space-y-2 p-6 border-b">
          <h3 class="h3">Create a new Monitoring group</h3>
          <p class="text-sm font-normal">
            Get insights on competitors, track rankings, and stay updated on changes in search results
          </p>
        </div>
      </div>
      <div class="mx-8 border-b">
        <div class="space-y-5 py-6">
          <onboarding-stepper
            :current-step="currentStep ?? 1"
            :steps="isBrandedMonitoring && currentStep > 1 ? 3 : 2"
          />
          <div class="flex items-center gap-3">
            <div v-if="hasSelectedMonitoringType && currentStep > 1">
              <p
                class="px-2 py-1 rounded-md"
                :class="isBrandedMonitoring ? 'blue-bg-3 blue-text-color' : 'red-bg-3 red-text-color'"
              >
                {{ isBrandedMonitoring ? 'Brand' : 'Generic' }}
              </p>
            </div>
            <p class="text-bb-text-secondary">
              <span class="font-bold"> Step {{ currentStep ?? 1 }}:</span>
              {{ STEP_DESCRIPTIONS[currentStep ?? 1] }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="onboardingWizardState.matches(STATES.BRANDED_OR_GENERIC_MONITORING)">
        <branded-or-generic-monitoring
          :saved-monitoring-type="onboardingWizardState.context.monitoringType"
          @select-monitoring="selectMonitoring"
        />
      </div>
      <div v-else-if="onboardingWizardState.matches(STATES.GENERIC_MONITORING)">
        <generic-monitoring
          :countries="countries || []"
          :generic-keywords="genericKeywords"
          :selected-country="selectedGenericCountry"
          :selected-generic-keywords="selectedGenericKeywords"
          :is-fetching-data="isFetchingData"
          @select-country="selectCountry"
          @add-keyword="addKeyword"
          @remove-keyword="removeKeyword"
        />
      </div>
      <div v-else-if="onboardingWizardState.matches(STATES.BRANDED_MONITORING)">
        <branded-monitoring
          :campaign-options="campaignOptions"
          :selected-campaign="selectedCampaign"
          :countries="countries || []"
          :selected-country="selectedBrandedCountry"
          :selected-keywords-with-campaign="selectedKeywordsWithCampaign"
          :selected-keywords-without-campaign="selectedKeywordsWithoutCampaign"
          :top-keywords="topKeywords"
          :top-geo-locations="geoTopGeoLocations"
          :top-account-keywords="topAccountKeywords"
          :is-fetching-data="isFetchingData"
          :saved-branded-monitoring-option="withBrandedCampaign"
          @select-campaign="selectCampaign"
          @select-country="selectCountry"
          @select-branded-monitoring-option="selectBrandedMonitoringOption"
          @add-keyword="addKeyword"
          @remove-keyword="removeKeyword"
        />
      </div>
      <div v-else-if="onboardingWizardState.matches(STATES.SETUP_INFRINGEMENTS_DETECTOR)">
        <setup-infringements-detector
          :trademarks="trademarks"
          :selected-trademarks="selectedTrademarks"
          :with-branded-campaign="withBrandedCampaign"
          @select-trademark="selectTrademark"
        />
      </div>
      <div v-else-if="onboardingWizardState.matches(STATES.FINAL_STEP)">
        <final-step />
      </div>

      <!--Buttons-->
      <div class="sticky bottom-0 pt-2 z-10 w-full bg-white">
        <div
          style="max-width: 640px; width: 100%"
          class="px-8 py-4"
        >
          <div
            class="flex flex-col items-center sm:flex-row sm:space-y-0 sm:py-0 pb-5 space-y-2"
            :class="currentStep > 1 ? 'justify-between' : 'justify-end'"
          >
            <!--Previous Step-->
            <merge-button-round
              v-if="currentStep > 1"
              button-type="secondary"
              @click="prevStep"
            >
              <template>
                <div class="w-32 flex items-center justify-center gap-2">
                  <ic-chevron
                    direction="left"
                    :size="16"
                  />
                  <p class="text-sm text-bb-text-default">Previous</p>
                </div>
              </template>
            </merge-button-round>
            <!--Next Step-->
            <merge-button-round
              v-if="!isFinalStep && currentStep > 1"
              :disabled="!enableNextButton"
              @click="nextStep"
            >
              <p class="w-32 text-center">Next</p>
            </merge-button-round>

            <!--Skip-->
            <merge-button-round
              v-if="isFinalStep && isBrandedMonitoring"
              button-type="tertiary"
              @click="startMonitoringGroup(true)"
            >
              <p class="w-32 text-center text-bb-brand-purple">Skip this step</p>
            </merge-button-round>

            <!--Start-->
            <merge-button-round
              v-if="isFinalStep"
              button-type="brand-purple"
              :disabled="!enableNextButton"
              @click="startMonitoringGroup"
            >
              <template>
                <div class="w-32 flex items-center justify-center gap-2">
                  <p class="text-sm text-white">Start</p>
                  <ic-rocket
                    direction="left"
                    :size="16"
                  />
                </div>
              </template>
            </merge-button-round>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { interpret, State } from 'xstate'

import Page from '@/components/base/page/Page.vue'

import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import OnboardingStepper from '@/views/site/search/competitor_monitoring_v2/components/OnboardingStepper.vue'
import OnboardingMachine, { STATES, EVENTS } from '../../../../../core/machines/cms-onboarding-wizard'
import BrandedMonitoring from '@/views/site/search/competitor_monitoring_v2/onboarding/steps/BrandedMonitoring'
import GenericMonitoring from '@/views/site/search/competitor_monitoring_v2/onboarding/steps/GenericMonitoring'
import BrandedOrGenericMonitoring from '@/views/site/search/competitor_monitoring_v2/onboarding/steps/BrandedOrGenericMonitoring.vue'
import SetupInfringementsDetector from '@/views/site/search/competitor_monitoring_v2/onboarding/steps/SetupInfringementsDetector'
import FinalStep from '@/views/site/search/competitor_monitoring_v2/onboarding/steps/FinalStep.vue'

// Icons
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcRocket from '@/components/icon/brightbid/IcRocket.vue'

// Style
import '@/views/site/search/competitor_monitoring_v2/styles/style.css'

const stateDefinition = OnboardingMachine.initialState
const startingState = State.create(stateDefinition)

// Vuex
import { mapActions, mapState } from 'vuex'

const STEP_DESCRIPTIONS = {
  1: 'Choose your goal',
  2: 'Create your first monitoring group',
  3: 'Set up infringement alerts',
}

export default {
  name: 'CmsOnboarding',
  components: {
    Page,
    OnboardingStepper,
    BrandedMonitoring,
    GenericMonitoring,
    BrandedOrGenericMonitoring,
    SetupInfringementsDetector,
    FinalStep,
    IcChevron,
    IcRocket,
    BrightbidLoader,
  },
  data() {
    return {
      onboardingWizardService: interpret(OnboardingMachine).start(startingState),
      onboardingWizardState: OnboardingMachine.initialState,
      STATES,
      EVENTS,
      STEP_DESCRIPTIONS,

      // Step 2: Data
      withBrandedCampaign: null,
      topKeywords: [],
      genericKeywords: [],
      geoTopGeoLocations: [],
      topAccountKeywords: [],

      // Step 2: Form
      selectedCampaign: null,
      selectedBrandedCountry: null,
      selectedKeywordsWithCampaign: [],
      selectedKeywordsWithoutCampaign: [],
      selectedLocations: [],
      selectedGenericKeywords: [],
      selectedGenericCountry: null,

      // Step 3:
      trademarks: [],
      selectedTrademarks: [],

      isFetchingData: false,
      isPageLoading: false,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('search', { campaigns: 'campaignsAll' }),
    ...mapState('country', ['countries']),
    ...mapState('competitorMonitoringV2', ['monitoringGroups']),
    hasSelectedMonitoringType() {
      return this.onboardingWizardState.context.monitoringType !== null
    },
    isBrandedMonitoring() {
      return this.onboardingWizardState.context.monitoringType === 'branded-monitoring'
    },
    isGenericMonitoring() {
      return this.onboardingWizardState.context.monitoringType === 'generic-monitoring'
    },
    currentStep() {
      return this.onboardingWizardState.context.currentStep
    },
    enableNextButton() {
      // check step specific conditions
      switch (this.currentStep) {
        case 1:
          return this.hasSelectedMonitoringType
        case 2:
          // BRANDED
          if (this.isBrandedMonitoring) {
            if (this.withBrandedCampaign === null) return

            // with campaign
            if (this.withBrandedCampaign) {
              //TODO: add location check
              return this.selectedCampaign?.value && this.selectedKeywordsWithCampaign.length > 0
            }
            // without campaign
            return this.selectedBrandedCountry?.value && this.selectedKeywordsWithoutCampaign.length > 0
          }
          // GENERIC MONITORING
          if (this.isGenericMonitoring) {
            return this.selectedGenericCountry?.value && this.selectedGenericKeywords.length > 0
          }
          return false
        case 3:
          return true
        default:
          return false
      }
    },
    isFinalStep() {
      if (this.isBrandedMonitoring && this.currentStep === 3) {
        return true
      }
      return !this.isBrandedMonitoring && this.currentStep === 2
    },
    campaignOptions() {
      if (!this.campaigns) {
        return []
      }
      return this.campaigns.map(campaign => ({ value: campaign.campaign_id, label: campaign.name }))
    },
  },
  async mounted() {
    await this.fetchCountries()
    await this.loadSearchCampaignsAll({ siteId: this.selectedSite.value, exclusion: 'DISPLAY' })
  },
  async created() {
    await this.initializeOnboarding()
  },
  methods: {
    ...mapActions('toast', ['loadToast']),
    ...mapActions('country', ['fetchCountries']),
    ...mapActions('competitorMonitoringV2', ['loadMonitoringGroups']),
    ...mapActions('search', ['loadSearchCampaignsAll']),
    async initializeOnboarding() {
      // set starting context
      const startingContext = {
        currentStep: 1,
        monitoringType: null,
      }

      this.onboardingWizardService = interpret(OnboardingMachine)
        .onTransition(state => {
          this.onboardingWizardState = state
        })
        .start(State.from(startingState.value, startingContext))
    },
    sendEvent(event) {
      this.onboardingWizardService.send(event)
    },

    // Onboarding Logic
    selectMonitoring(monitoringType) {
      this.sendEvent({ type: EVENTS.SET_MONITORING_TYPE, payload: { monitoringType } })
      this.sendEvent(EVENTS.NEXT)
    },
    nextStep() {
      if (this.currentStep === 2 && this.isBrandedMonitoring) {
        this.trademarks = this.withBrandedCampaign
          ? structuredClone(this.selectedKeywordsWithCampaign)
          : structuredClone(this.selectedKeywordsWithoutCampaign)
      }
      this.sendEvent(EVENTS.NEXT)
    },
    prevStep() {
      this.sendEvent(EVENTS.BACK)
    },

    // EVENTS
    async selectCampaign(campaign) {
      this.isPageLoading = true
      this.isFetchingData = true

      this.selectedCampaign = campaign

      const [topGeoLocations, topKeywords] = await Promise.all([
        this.getTopGeoLocations(this.selectedCampaign.value),
        this.getTopKeywordsClicks(),
      ])
      this.topKeywords =
        topKeywords.map((_, index) => {
          return {
            ..._,
            id: `${_.keyword_text}-${index}`,
          }
        }) || []
      this.geoTopGeoLocations = topGeoLocations || []

      this.selectedKeywordsWithCampaign = structuredClone(this.topKeywords)
        .slice(0, 5)
        .map(({ keyword_text, id }) => ({ label: keyword_text, value: id }))

      this.isFetchingData = false
      this.isPageLoading = false
    },
    async selectCountry(country) {
      this.isPageLoading = true
      this.isFetchingData = true

      const fetchedSearchTerms =
        (await this.fetchTopSearchTermsClicks())?.map(({ search_term, cost, clicks }, index) => ({
          keyword_text: search_term,
          id: `${search_term}-${index}`,
          cost,
          clicks,
        })) || []

      const uniqueKeywords = new Set()
      const selectedKeywords = fetchedSearchTerms
        .filter(({ keyword_text }) => {
          if (uniqueKeywords.has(keyword_text)) {
            return false
          }
          uniqueKeywords.add(keyword_text)
          return true
        })
        .slice(0, 5)
        .map(({ keyword_text, id }) => ({ label: keyword_text, value: id }))

      if (this.isBrandedMonitoring) {
        this.selectedBrandedCountry = country
        this.topAccountKeywords = fetchedSearchTerms
        this.selectedKeywordsWithoutCampaign = selectedKeywords
      } else if (this.isGenericMonitoring) {
        this.selectedGenericCountry = country
        this.genericKeywords = fetchedSearchTerms
        this.selectedGenericKeywords = selectedKeywords
      }

      this.isFetchingData = false
      this.isPageLoading = false
    },
    selectBrandedMonitoringOption(option) {
      let withBrandedCampaign = option === 'with-campaign'
      if (!option) withBrandedCampaign = null
      this.withBrandedCampaign = withBrandedCampaign
    },
    addKeyword({ keyword, type }) {
      if (type === 'with-campaign') {
        this.selectedKeywordsWithCampaign.push(keyword)
      } else if (type === 'without-campaign') {
        this.selectedKeywordsWithoutCampaign.push(keyword)
      } else if (type === 'generic') {
        this.selectedGenericKeywords.push(keyword)
      }
    },
    removeKeyword({ index, type }) {
      if (type === 'with-campaign') {
        this.selectedKeywordsWithCampaign.splice(index, 1)
      } else if (type === 'without-campaign') {
        this.selectedKeywordsWithoutCampaign.splice(index, 1)
      } else if (type === 'generic') {
        this.selectedGenericKeywords.splice(index, 1)
      }
    },

    selectTrademark(trademark) {
      if (this.selectedTrademarks.includes(trademark)) {
        this.selectedTrademarks = this.selectedTrademarks.filter(c => c.value !== trademark.value)
        return
      }
      this.selectedTrademarks.push(trademark)
    },

    // DATA FETCHING
    /*
     * Get Top Geolocations
     */
    async getTopGeoLocations(campaignId, n = 50) {
      try {
        let { data: topGeoLocations } = await this.$http.get(
          `/search/site/${this.selectedSite.value}/campaign/${campaignId}/top-n-geo-locations/${n}`,
        )

        topGeoLocations = topGeoLocations.filter(location => {
          return !Object.prototype.hasOwnProperty.call(location, 'country_code') || !!location.country_code
        })

        return topGeoLocations
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get top geo locations. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Get Top Keywords
     */
    async getTopKeywordsClicks(n = 50) {
      try {
        const { data: topKeywords } = await this.$http.get(
          `/search/site/${this.selectedSite.value}/campaign/${this.selectedCampaign.value}/fetch-top-n-keywords-clicks/${n}`,
        )
        return topKeywords
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get top keywords. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Get Generic Keywords
     */
    async fetchTopSearchTermsClicks(n = 50, lookbackDays = 30) {
      try {
        const { data: searchTerms } = await this.$http.get(
          `/search/site/${this.selectedSite.value}/fetch-top-n-search-terms-clicks/${n}/${lookbackDays}`,
        )
        return searchTerms.data
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to load search terms. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Start Monitoring Group
     */
    async startMonitoringGroup(skip = false) {
      this.isPageLoading = true

      const enableInfringementsDetector = !skip && !this.isGenericMonitoring

      // Create the payload
      const payload = this.createMonitoringGroupPayload(enableInfringementsDetector)
      if (!payload) {
        this.isPageLoading = false
        return
      }
      // Create Monitoring Group v3
      const monitoringGroup = await this.createMonitoringGroupV3(payload)

      // Activate Monitoring Group
      if (!monitoringGroup?.id) {
        // TODO: return error
        return
      }
      await this.activateMonitoringGroup(monitoringGroup.id, 'full-version')

      // Reload Monitoring Groups
      await this.loadMonitoringGroups(this.selectedSite.value)

      this.isPageLoading = false
      await this.$router.push({ name: 'all-groups' })
    },

    /*
     * Create Monitoring Group Payload
     */
    createMonitoringGroupPayload(enableInfringementsDetector) {
      const payload = {
        googleCampaignId: null,
        name: null,
        frequencySeconds: 3600,
        keywords: [],
        creditLeft: 1000,
        geoLocations: [],
        type: null,
      }

      switch (true) {
        case this.isBrandedMonitoring && this.withBrandedCampaign:
          payload.googleCampaignId = this.selectedCampaign.value
          payload.name = `${this.selectedSite.label} Brand Monitoring ${this.monitoringGroups.length + 1}`
          payload.type = 'brand'
          payload.keywords = this.selectedKeywordsWithCampaign.map(keyword => ({
            keyword: keyword.label,
            keywordType: 'brand',
            infringementActive: enableInfringementsDetector ?? false,
            smartSavingActive: false,
          }))
          payload.geoLocations = this.geoTopGeoLocations.map(location => ({
            name: location.name,
            countryCode: location.country_code,
          }))
          return payload

        case this.isBrandedMonitoring && !this.withBrandedCampaign:
          payload.googleCampaignId = null
          payload.name = `${this.selectedSite.label} - ${this.selectedBrandedCountry.value} Brand Monitoring ${this.monitoringGroups.length + 1}`
          payload.type = 'brand'
          payload.keywords = this.selectedKeywordsWithoutCampaign.map(keyword => ({
            keyword: keyword.label,
            keywordType: 'brand',
            infringementActive: enableInfringementsDetector ?? false,
            smartSavingActive: false,
          }))
          payload.geoLocations = [
            { name: this.selectedBrandedCountry.label, countryCode: this.selectedBrandedCountry.value },
          ]
          return payload

        case this.isGenericMonitoring:
          payload.type = 'generic'
          payload.name = `${this.selectedSite.label} Generic Monitoring ${this.monitoringGroups.length + 1}`
          payload.keywords = this.selectedGenericKeywords.map(keyword => ({
            keyword: keyword.label,
            keywordType: 'generic',
            infringementActive: false,
            smartSavingActive: false,
          }))
          payload.geoLocations = [
            { name: this.selectedGenericCountry.label, countryCode: this.selectedGenericCountry.value },
          ]
          return payload

        default:
          return null
      }
    },

    /*
     * Create Monitoring Group
     */
    async createMonitoringGroupV3(payload) {
      try {
        const { data } = await this.$http.post(`/cms/site/${this.selectedSite.value}/monitoring-job/v3`, payload)
        return data
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to create monitoring group. Please try again later.',
          type: 'error',
        })
      }
    },
    /*
     * Activate CMS Monitoring Group
     */
    async activateMonitoringGroup(groupId, subscriptionType = 'full-version') {
      try {
        let evaluationUrl = subscriptionType === 'free-trial' ? '/evaluation?seconds=1209600' : ''
        await this.$http.put(`/cms/site/${this.selectedSite.value}/monitoring-job/${groupId}/start${evaluationUrl}`)
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to activate monitoring group. Please try again later.',
          type: 'error',
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.onboarding-page {
  overflow: auto;
  height: calc(100vh - 103px);
}
</style>
