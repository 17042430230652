<template>
  <div
    :style="`height: ${size}px; width: ${size}px`"
    class="rounded-full border flex items-center justify-center duration-300 ease-linear cursor-pointer"
    :class="isHovered ? active.border : inactive.border"
    @click="$emit('click')"
  >
    <div
      class="rounded-full duration-300 ease-linear"
      :class="[isSelected ? `${active.border} ${active.background} border` : '']"
      :style="`height: ${size - radioPadding}px; width: ${size - radioPadding}px`"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'RadioButtonV2',
  props: {
    size: {
      type: Number,
      default: 16,
    },
    radioPadding: {
      type: Number,
      default: 6,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Object,
      default: () => {
        return {
          border: 'border-bb-brand-purple',
          background: 'bg-bb-brand-purple',
        }
      },
    },
    inactive: {
      type: Object,
      default: () => {
        return {
          border: 'border-bb-disabled-gray',
          background: '',
        }
      },
    },
  },
}
</script>

<style scoped lang="scss"></style>
